import { defineStore, acceptHMRUpdate } from 'pinia';
import * as backend from '@/services/backend';
import type { ChartData, TimeUnit } from 'chart.js';
import { useLinkedDevicesStore } from './linkedDevices';
import { addHours } from 'date-fns';
import { SubscriptionType } from '@/model';
import * as d3 from 'd3';

export type LicenseOption = {
  type: SubscriptionType;
  team?: { name: string; id: number };
};

type By = 'device' | 'isp' | 'adapter' | 'server';

type State = {
  __individualTunnel: backend.IndividualTunnelStats | null;
  __individualNet: backend.IndividualNetStats | null;
  __teamTunnel: backend.TeamTunnelStats | null;
  __teamNet: backend.TeamNetStats | null;
  __dedicatedServerTunnel: backend.DedicatedServerTunnelStats | null;
  __fetched: boolean,
  license: LicenseOption,
  timeframe: [Date, Date];
  unit: TimeUnit | undefined;
  grouped: Record<By, { x: any; y: number; by: By }[]>;
  by: By;
};

const yesterday = addHours(new Date(), -24);
const eightDaysAgo = addHours(yesterday, -7 * 24);

export const useChartStore = defineStore('chart', {
  state: (): State => ({
    __individualTunnel: null,
    __individualNet: null,
    __teamTunnel: null,
    __teamNet: null,
    __dedicatedServerTunnel: null,
    __fetched: false,
    license: { type: 'individual' },
    timeframe: [eightDaysAgo, yesterday],
    unit: undefined,
    grouped: {} as any,
    by: 'device',
  }),
  getters: {
    table: state => {
      return Object.entries(state.grouped)
        .map(([by, arr]) => ({
          field: by,
          total: arr.reduce((acc, curr) => acc + curr.y, 0),
          // the compiler is not aggresively type-inferring
        } as { field: By, total: number }))
        .toSorted((a, b) => b.total - a.total);
    },
    line(state): ChartData<'line'> | null {
      if (!state.grouped) return null;

      const linkedDevicesStore = useLinkedDevicesStore();
      const normalizeLabel = (label: string) => {
        if (state.by === 'device') {
          return linkedDevicesStore.nameof(label) ?? label.slice(0, 8)
        }
        return label;
      };

      const rgbs = Array.prototype.concat(
        d3.schemeObservable10,
        d3.schemeCategory10,
        d3.schemeAccent,
        d3.schemePaired,
        d3.schemePastel1,
        d3.schemePastel2,
        d3.schemeSet1,
        d3.schemeSet2,
        d3.schemeSet3,
        d3.schemeTableau10,
        d3.schemeDark2,
      );

      const datasets = Object.entries(state.grouped).map(([by, arr], ix) => ({
        data: arr, // TODO: interperse 0s
        // data: arr.reduce(
        //   (acc, curr, idx) => {
        //     acc.push({ ...curr, y: curr.y + (acc[idx - 1]?.y ?? 0) });
        //     return acc;
        //   },
        //   [] as typeof arr,
        // ),
        label: normalizeLabel(by),
        // fill: 'origin',
        by: by as By,
        borderColor: rgbs[ix],
        backgroundColor: rgbs[ix],
        tension: 0.4, // curve smoothing
      }));
      const map = new Map(this.table.map(record => [record.field, record.total]));
      datasets.sort((a, b) => map.get(b.by)! - map.get(a.by)!);
      return { labels: [], datasets };
    },
    // the timeframe is intialized to [8 days ago, yesterday]
    // on the first data fetching without any user operation, we try to saturate
    // the chart by shrinking the timeframe to its bounds.
    //
    // The first fetch() could return empty data. We do not shrink timeframe
    // in that case. Neither should we shrink it in future fetch() calls
    // if it returns any data, because, the timeframe is already shrinked once
    // during the first fetch()
    __shouldShrinkTimeframe: state => {
      const changed =
        state.timeframe[0].getTime() !== eightDaysAgo.getTime() ||
        state.timeframe[1].getTime() !== yesterday.getTime();
      return changed && !state.__fetched;
    }
  },
  actions: {
    async fetch(timeframe?: [Date, Date]) {
      const [ from, to ] = timeframe ?? this.$state.timeframe;

      const verb = ['device', 'user', 'server'].includes(this.$state.by) ? 'tunnel' : 'net';

      const initTimeframe = (dates: Date[]) => {
        let [min, max] = [new Date(), new Date(0)];
        dates.forEach(time => {
          if (time < min) min = time;
          if (time > max) max = time;
        });
        this.$state.timeframe = [min, max];
        this.$state.__fetched = true;
      };

      if (this.$state.license.type === 'individual' && verb === 'tunnel') {
        this.$state.__individualTunnel = await backend.individualTunnelStats({ from, to });

        if (!this.$state.__individualTunnel.values.length) {
          this.$state.__fetched = true;
          return;
        }

        if (!this.__shouldShrinkTimeframe) {
          initTimeframe(this.$state.__individualTunnel.values.map(([_, time]) => time));
        }

        const raw = this.$state.__individualTunnel.values.map(([usage, time, device, _ratio]) => ({
          x: time,
          y: usage,
          device,
        }));
        return this.$state.grouped = Object.groupBy(raw, ({ device }) => device) as any;
      }

      if (this.$state.license.type === 'individual' && verb === 'net') {
        this.$state.__individualNet = await backend.individualNetStats({ from, to });
        this.$state.__fetched = true;

        if (!this.$state.__individualNet.values.length) {
          this.$state.__fetched = true;
          return;
        }

        if (!this.__shouldShrinkTimeframe) {
          initTimeframe(this.$state.__individualNet.values.map(([_, time]) => time));
        }

        const raw = this.$state.__individualNet.values.map(([usage, time, __device, isp, adapter]) => ({
          x: time,
          y: usage,
          isp,
          adapter,
        }));
        return (this.$state.grouped = Object.groupBy(
          raw,
          record => record[this.$state.by as 'isp' | 'adapter'],
        ) as any);
      }

      if (this.$state.license.type === 'teams' && verb === 'tunnel') {
        this.$state.__teamTunnel = await backend.teamsTunnelStats(this.$state.license.team!.id, { from, to });
        this.$state.__fetched = true;

        if (!this.$state.__teamTunnel.values.length) {
          this.$state.__fetched = true;
          return;
        }

        if (!this.__shouldShrinkTimeframe) {
          initTimeframe(this.$state.__teamTunnel.values.map(([_, time]) => time));
        }

        const raw = this.$state.__teamTunnel.values.map(([usage, time, device, __id]) => ({
          x: time,
          y: usage,
          device,
        }));
        return this.$state.grouped = Object.groupBy(raw, ({ device }) => device) as any;
      }

      if (this.$state.license.type === 'teams' && verb === 'net') {
        this.$state.__teamNet = await backend.teamsNetStats(this.$state.license.team!.id, { from, to });
        this.$state.__fetched = true;

        if (!this.$state.__teamNet.values.length) {
          this.$state.__fetched = true;
          return;
        }

        if (!this.__shouldShrinkTimeframe) {
          initTimeframe(this.$state.__teamNet.values.map(([_, time]) => time));
        }

        const raw = this.$state.__teamNet.values.map(([usage, time, __device, isp, adapter]) => ({
          x: time,
          y: usage,
          isp,
          adapter,
        }));
        return (this.$state.grouped = Object.groupBy(
          raw,
          record => record[this.$state.by as 'isp' | 'adapter'],
        ) as any);
      }

      if (this.$state.license.type === 'dedicated_server' && verb === 'tunnel') {
        this.$state.__dedicatedServerTunnel = await backend.dedicatedServerTunnelStats({ from, to });
        this.$state.__fetched = true;

        if (!this.$state.__dedicatedServerTunnel.values.length) {
          this.$state.__fetched = true;
          return;
        }

        if (!this.__shouldShrinkTimeframe) {
          initTimeframe(this.$state.__dedicatedServerTunnel.values.map(([_, time]) => time));
        }

        const raw = this.$state.__dedicatedServerTunnel.values.map(([usage, time, device, server]) => ({
          x: time,
          y: usage,
          device,
          server,
        }));
        return this.$state.grouped = Object.groupBy(raw, rec => rec[this.$state.by as 'device' | 'server']) as any;
      }
    },
  },
});

import.meta.hot?.accept(acceptHMRUpdate(useChartStore, import.meta.hot));
