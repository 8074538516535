import * as Plot from "@observablehq/plot";
import { h, defineComponent } from 'vue';

export default defineComponent({
  name: 'PlotFigure',
  props: { options: { type: Object, required: true } },
  setup(props: { options: Plot.PlotOptions }) {
    return () => h('div', { innerHTML: Plot.plot({ ...props.options }).outerHTML });
  },
});
