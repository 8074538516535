<template>
  <PageNavbar />
  <AuthGuard>
    <div id="content" class="container mt-5">
      <h1>Statistics</h1>
      <Card class="mb-5" style="align-items: flex-start">
        <template #content>
          <div style="display: flex; flex-direction: column;">
            <span>Filter By:</span>
            <Select
              v-model="license"
              variant="filled"
              :options="filterByOpts"
              :optionLabel="licenseNameOf"
              pt:root:style="border: none; background-color: var(--p-card-background)"
              pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none; padding-left: 0"
              pt:dropdown:style="color: #00adee;"
            ></Select>
            <small v-if="store.license?.type === 'individual'">{{ userStore.email }}</small>
          </div>
        </template>
      </Card>

      <Card>
        <template #content>
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; flex-direction: row">
              <h3>Report:</h3>
              <Select
                v-model="report"
                variant="filled"
                :options="['Total Usage']"
                pt:root:class="py-0 pr-0 ps-2"
                pt:root:style="border: none; background-color: var(--p-card-background);"
                pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none; padding: 0"
                pt:dropdown:style="color: #00adee"
              ></Select>
            </div>

            <div v-if="timeframe" style="display: flex; flex-direction: row">
              <h3>Timeframe:</h3>
              <DatePicker
                v-model="timeframe"
                selectionMode="range"
                showTime
                :minDate="twoWeeksAgo"
                :maxDate="new Date()"
                :hourFormat="hourFormat"
                :manualInput="false"
                pt:pcInputText:root:style="color: #00adee; width: 35ch; font-weight: 700; font-size: 18px"
                pt:pcInputText:root:class="py-0 ps-2 pe-0 border-0"
              />
              <h3>Unit:</h3>
              <Select
                v-if="store.unit"
                v-model="store.unit"
                variant="filled"
                :options="['minute', 'hour', 'day', 'week']"
                pt:label:class="py-0 ps-2 pe-0"
                pt:root:style="border: none; background-color: var(--p-card-background);"
                pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none;"
                pt:dropdown:style="color: #00adee"
              ></Select>
            </div>
          </div>
          <small>Total data transferred through Speedify (includes upload and download)</small>

          <Chart
            v-if="['individual', 'teams', 'dedicated_server'].includes(store.license?.type) && store.line"
            ref="chart"
            type="line"
            :data="store.line as ChartData<'line'>"
            :options="{
              maintainAspectRatio: false, // https://www.chartjs.org/docs/latest/configuration/responsive.html#configuration-options
              scales: {
                x: { type: 'time', time: { unit: store.unit }, min: store.timeframe?.[0] as any, max: store.timeframe?.[1] as any },
                y: {
                  title: { text: 'Usage' },
                  ticks: { callback: (value: number) => isNaN(value) ? '0 B' : humanFileSize(value) },
                },
              },
              plugins: {
                tooltip: { callbacks: { label: ctx => `${ctx.dataset.label}: ${humanFileSize(ctx.parsed.y)}` } },
              },
              elements: { line: { tension: 0.4 } },
            } as ChartOptions<'line'>"
            :plugins="[{
              id: 'plugin',
              afterDraw: chart => store.unit = store.unit ?? (chart.scales.x! as any)._unit,
            }] as Plugin<'line'>[]"
            style="height: 25rem;"
          />
          <Skeleton v-else shape="rectangle" size="10rem"></Skeleton>

          <div style="display: flex; flex-direction: row; align-items: center">
            <h3 class="mb-0">Break Down By:</h3>
            <Select
              v-model="by"
              variant="filled"
              :options="
                store.license.type === 'dedicated_server'
                  ? breakdownOpts.filter(x => ['device', 'server'].includes(x.code))
                  : breakdownOpts.filter(x => ['device', 'isp', 'adapter'].includes(x.code))
              "
              optionLabel="name"
              pt:root:style="border: none; background-color: var(--p-card-background);"
              pt:label:style="font-weight: 700; font-size: 24px; color: #00adee; background-color: none;"
              pt:dropdown:style="color: #00adee"
              pt:label:class="py-0 ps-2 pe-0 fs-3"
            ></Select>
          </div>

          <!-- DataTable is impure: https://github.com/primefaces/primevue/issues/5261 -->
          <DataTable v-if="store.table && ['individual', 'teams', 'dedicated_server'].includes(store.license?.type)" :value="store.table">
            <Column
              :field="normalizeTableName"
              :header="by.name"
              sortable
              :pt="{ headerCell: ({}) => ({ style: 'background-color: #F9FAFB' }) }"
            ></Column>
            <Column
              field="total"
              header="Total Usage"
              sortable
              :pt="{ headerCell: ({}) => ({ style: 'background-color: #F9FAFB' }) }"
            >
              <template #body="slotProps">{{ humanFileSize(slotProps.data.total) }}</template>
            </Column>
          </DataTable>
        </template>
      </Card>

      <PlotFigure
        :options="{}"
      ></PlotFigure>
    </div>
  </AuthGuard>
  <PageFooter />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import type { ChartData, Plugin, ChartOptions } from 'chart.js';
import { addHours } from 'date-fns';
import 'chartjs-adapter-date-fns';
import * as Sentry from '@sentry/vue';

import Skeleton from 'primevue/skeleton';
import Chart from 'primevue/chart';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Select from 'primevue/select';
import DatePicker from 'primevue/datepicker';

import AuthGuard from '@/components/AuthGuard.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageNavbar from '@/components/PageNavbar.vue';
import PlotFigure from '@/components/PlotFigure.ts';

import { useUserStore } from '@/store/user';
import { useChartStore, LicenseOption } from '@/store/chart';
import { useLinkedDevicesStore } from '@/store/linkedDevices';
import { humanFileSize, expose } from '@/util';
import { isOwnerOrAdmin } from '@/services/subscription';

const userStore = useUserStore();
const store = useChartStore();
const linkedDevicesStore = useLinkedDevicesStore();
const { subscriptions } = storeToRefs(userStore);

const twoWeeksAgo = addHours(new Date(), -24 * 14);
const normalizeTableName = (item: { field: string, tolal: number}) => {
  if (store.by === 'device') return deviceNameOf(item.field);
  if (store.by === 'isp') return item.field || 'UNKNOWN'; // TODO: style __UNKNOWN__
  if (store.by === 'adapter') {
    if (item.field === 'NetworkShare Client') return 'Pair & Share Client';
    if (item.field === 'NetworkShare Host') return 'Pair & Share Host';
  }
  // Ethernet
  // Wi-Fi
  // Cellular
  // VPN
  // Loopback
  // Bluetooth
  // NetworkShare Client
  // NetworkShare Host
  // Unknown
  return item.field;
};

const license = computed({
  get: () => store.license,
  set: x => { store.license = x; void store.fetch() },
})
// TODO: dedicated_server, router licenses
const filterByOpts = computed<LicenseOption[]>(() => {
  const hashset = new Set<string>(); // XXX: ridiculously no true hashset

  for (const sub of subscriptions.value ?? []) {
    switch (sub.type) {
      case 'individual':
        hashset.add(JSON.stringify({ type: sub.type }));
        break;
      case 'teams': {
        if (!isOwnerOrAdmin(sub)) break;

        hashset.add(JSON.stringify({ type: sub.type, team: { name: sub.team!.name, id: sub.team!.teamId }}));
        break;
      }
      case 'families':
        hashset.add(JSON.stringify({ type: sub.type, team: { name: sub.team!.name, id: sub.team!.teamId }}));
        break;
      case 'dedicated_server':
        hashset.add(JSON.stringify({ type: sub.type }));
        break;
      case 'router':
        // TODO: hashset.add(JSON.stringify({ type: sub.type }));
        break;
    }
  }

  return Array.from(hashset).map(x => JSON.parse(x));
});
const report = ref('Total Usage');
const by = computed({
  get: () => ({ code: store.by, name: { isp: 'ISP', adapter: 'Adapter Type', device: 'Device', server: 'Server' }[store.by] }),
  // FIXME: how to disbale rectivity on mutating store.by?
  set: ({ code }) => { store.by = code; void store.fetch() },
});

const breakdownOpts = [
  { name: 'Device', code: 'device' },
  { name: 'ISP', code: 'isp' },
  { name: 'Adapter Type', code: 'adapter' },
  { name: 'Server', code: 'server' },
];
const chart = ref<typeof Chart>();
const timeframe = computed({
  get: () => store.timeframe,
  set: x => {
    const [from, to] = x ?? [];
    from &&
      to &&
      store
        .fetch([ from, to ])
        .then(() => (store.timeframe = x))
        .catch(Sentry.captureException);

    return x;
  },
});

const deviceNameOf = (uuid: string) => linkedDevicesStore.nameof(uuid) ?? uuid.slice(0, 8);
import.meta.env.DEV && expose({ chart, report, store });

onMounted(async () => {
  await linkedDevicesStore.refresh();

  await store.fetch(timeframe.value);
});

const hourFormat = Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hour12 ? '12' : '24';
const licenseNameOf = (opt: LicenseOption) => {
  switch (opt.type) {
    case 'individual':
      return 'Individual';
    case 'dedicated_server':
      return 'My Dedicated Server';
    case 'families':
      return 'My Family';
    case 'router':
      return 'Router';
    case 'teams': {
      // Call it 'Team' when there is only one. Teams are usually unnamed
      const numTeams = subscriptions.value.map(x => x.type === 'teams').reduce((acc, curr) => acc + +curr, 0);
      return numTeams === 1 && opt.team!.name.match(/team-[a-z0-9]{40}/) ? 'My Team' : opt.team!.name;
    }
  }
};
</script>
