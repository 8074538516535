export const config = {
  sentry: {
    enabled: Number(import.meta.env.VITE_SENTRY_ENABLED) === 1,
    env: import.meta.env.VITE_SENTRY_ENV,
  },
  subsmgmt: {
    enableLinkedDevicesView: !!Number(import.meta.env.VITE_ENABLE_LINKED_DEVICES_VIEW),
    enableLandingPage: !!+import.meta.env.VITE_ENABLE_LANDING_PAGE,
    enableQrCodeLogin: !!+import.meta.env.VITE_ENABLE_QRCODE_LOGIN,
    enableThemeChooser: !!+import.meta.env.VITE_SHOW_THEME_CHOOSER,
    enableStatisticsPage: !!+import.meta.env.VITE_ENABLE_STATISTICS_PAGE,
  },
  recaptcha: {
    enabled: !!+import.meta.env.VITE_RECAPTCHA_ENABLED,
    sitekey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  },
  oidc: {
    zitadel: { enabled: !!+import.meta.env.VITE_OAUTH2_ZITADEL_ENABLED },
    google: { enabled: !!+import.meta.env.VITE_OAUTH2_GOOGLE_ENABLED },
    apple: { enabled: !!+import.meta.env.VITE_OAUTH2_APPLE_ENABLED },
  },
  partnerRouterVendors: (import.meta.env.VITE_PARTNER_ROUTER_VENDOR_NAMES as string ?? "").split(","),
};
