import { createRouter, createWebHistory, LocationQueryValue, RouteRecordRaw, RouteLocationRaw } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import AccountDashboard from '@/views/AccountDashboard.vue';
import DeviceActivation from '@/views/DeviceActivation.vue';
import BillingView from '@/views/BillingView.vue';
import SubscriptionPortal from '@/views/SubscriptionPortal.vue';
import UpgradePortal from '@/views/UpgradePortal.vue';
import Login from '@/views/Login.vue';
import Statistics from '@/views/Statistics.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import QrCodeLogin from '@/views/QrCodeLogin.vue';
import RouterCouponRedemption from '@/views/RouterCouponRedemption.vue';
import { hasValidUser, isUserAdminOrOwner, isUserAnOwner, loginRedirect } from '@/services/navigationGuards';
import { useUserStore } from '@/store/user';
import { getToken, isAuthenticated, api } from '@/services/backend';
import { config } from '@/config';

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth: boolean;
    marketingUpgradePush?: boolean;
    requiresOwner?: boolean;
    requiresAdminOrOwner?: boolean;
    title?: string;
  }
}

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/activate',
    name: 'DeviceActivation',
    component: DeviceActivation,
    meta: {
      title: 'Device Activation',
      requiresAuth: true,
    },
  },
  {
    path: '/redeem-router-coupon',
    name: 'RouterCouponRedemption',
    component: RouterCouponRedemption,
    meta: {
      title: 'Redeem a Router Coupon',
      requiresAuth: true,
    },
  },
  {
    path: '/',
    redirect: () => ({ name: 'AccountDashboard' }),
  },
  {
    path: '/account',
    name: 'AccountDashboard',
    component: AccountDashboard,
    meta: {
      requiresAuth: true,
      title: 'Your Speedify Account',
    },
  },
  {
    // Another hacky way for urls like
    // `https://${MY_SPEEDIFY}/users/abc@connectify.me`
    // `https://${MY_SPEEDIFY}/users/abc@connectify.me/subscriptions/:referenceId`
    //
    // See https://github.com/Connectify/speedify-subs-mgmt-v2/issues/44
    path: '/users/:email',
    children: [
      {
        path: '',
        component: { template: 'dummy' },
        beforeEnter: async to => {
          const { email } = to.params;
          if ((await getToken())?.email === email) return { name: 'AccountDashboard' };

          return { name: 'Login', query: { login_hint: email, redirect: to.fullPath } };
        },
        meta: { requiresAuth: false },
      },
      {
        path: 'subscriptions/:referenceId',
        component: { template: 'dummy' },
        beforeEnter: async to => {
          const { email, referenceId } = to.params;
          if ((await getToken())?.email === email)
            return { name: 'SubscriptionPortal', query: { referenceId } };

          return { name: 'Login', query: { login_hint: email, redirect: to.fullPath } };
        },
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/account/upgrade',
    name: 'LegacyUpgradePortalShortcut',
    component: UpgradePortal,
    meta: {
      requiresAuth: true,
      title: 'Upgrade your Speedify Subscription',
      requiresOwner: true,
      marketingUpgradePush: true,
    },
  },
  {
    path: '/account/statistics',
    name: 'Statistics',
    component: Statistics,
    beforeEnter: _ => config.subsmgmt.enableStatisticsPage || { name: 'AccountDashboard' },
    meta: {
      requiresAuth: true,
      title: 'Statistics',
    },
  },
  {
    path: '/billing',
    name: 'BillingView',
    component: BillingView,
    meta: {
      requiresAuth: true,
      title: 'Billing Information',
      requiresOwner: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/qrcode-login/:id',
    name: 'QrCodeLogin',
    component: QrCodeLogin,
  },
  {
    // something about this breaks if accessed directly, without redirect in sessionStorage
    // 'ce' or 'pe' is null. try/catch does not work here since the error is thrown in main.ts?
    path: '/auth/callback/:provider',
    redirect: to => {
      if (to.query.redirect) {
        return loginRedirect(to.query.redirect as string) as RouteLocationRaw;
      }
      const redirect = sessionStorage.getItem('redirect')!
      return loginRedirect(redirect) as RouteLocationRaw;
    },
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: WelcomeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/subscription',
    name: 'SubscriptionPortal',
    component: SubscriptionPortal,
    meta: {
      requiresAuth: true,
      title: 'Manage your subscription',
      requiresAdminOrOwner: true,
    },
  },
  {
    path: '/upgrade',
    name: 'UpgradePortal',
    component: UpgradePortal,
    meta: {
      requiresAuth: true,
      title: 'Upgrade your Speedify Subscription',
      requiresOwner: true,
    },
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      title: 'Forgot Password',
    },
  },
  {
    path: '/password/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password-set',
    redirect: to => ({ path: `/password/reset/${to.query.token as LocationQueryValue}` }),
  },
  {
    // will match everything and put it under `$route.params.pathMatch`
    path: '/:pathMatch(.*)*',
    // empty params because of https://github.com/vuejs/router/issues/1617
    redirect: { name: 'AccountDashboard', params: {} },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

// server checks for session auth before loading app
router.beforeEach(async to => {
  const toast = useToast(); // XXX: must be called before pinia
  const userStore = useUserStore();

  if (to.meta?.requiresAuth && !await isAuthenticated()) {
    return { name: 'Login', query: { redirect: to.fullPath } };
  }

  if (to.name === 'DeviceActivation' || to.name === 'RouterCouponRedemption') {
    // XXX: ugly hack to handle a case when the token cookie is out of sync with sid cookie which
    // means the token cookie thinks it is logged, actually not.
    // In other words, isAuthenticated() is not guaranteed to return the truth
    return await api
      .get('/api/user')
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      .unauthorized(() => { throw { name: 'Login', query: { redirect: to.fullPath } }; })
      .res(() => true)
      .catch(() => ({ name: 'Login', query: { redirect: to.fullPath } }));
  }
  // TODO: remove the ugly hack due to necessity of userStore.subscriptions
  if (to.name === 'DeviceActivation') return true;
  if (to.name === 'RouterCouponRedemption') return true;
  if (to.name === 'ForgotPassword') return true;
  if (to.name === 'ResetPassword') return true;
  if (to.name === 'Login') return true;
  if (to.name === 'AccountDashboard') return true;
  if (to.name === 'Welcome') return true;

  if (to.fullPath.startsWith('/users')) return true;

  // FIXME: again this makes AuthGuard useless
  const ensureValidUser = async () => {
    if (!await hasValidUser()) {
      try {
        await useUserStore().refresh();
      } catch (err) {
        toast.add({
          severity: 'error',
          summary: 'Something went wrong',
          detail: import.meta.env.DEV ? 'See the browser console for details' : 'Please conact support@speedify.com',
        });
        throw err;
      }
    }
  };

  if (to.meta.marketingUpgradePush) {
    await ensureValidUser();

    // marketing will send out emails with links to urge people to upgrade. these links will look
    // like
    // https://my.speedify.com/account/upgrade/
    //    ?plan=speedify-teams-unlimited-yearly
    //    &currentPlan=speedify-families-unlimited-yearly
    //    &utm_source=mandrill&utm_medium=email&utm_campaign=upgrade-pitch&utm_content=fam1y
    // plan => planCode that marketing wants user to upgrade to
    // currentPlan => current planCode that is targeted for an upgrade
    const isTemplatedMarketingUpgradePush = to.query.currentPlan !== undefined;

    if (isTemplatedMarketingUpgradePush) {
      const currentPlanCode = to.query.currentPlan;
      const relevantPlansRegisteredToUser = userStore.subscriptions.filter(s => s.planCode === currentPlanCode);

      // this should never happen but let's guard it anyway
      if (relevantPlansRegisteredToUser.length > 1) {
        return { name: 'AccountDashboard' };
      }

      // this should also never happen unless whatever query marketing uses in their templates is incorrect
      if (relevantPlansRegisteredToUser.length === 0) {
        to.meta.productNotFound = true;
        return true;
      }

      to.meta.referenceId = relevantPlansRegisteredToUser[0]!.referenceId;
      return true;
    }

    if (userStore.subscriptions.length !== 1) {
      return { name: 'AccountDashboard' };
    }

    to.meta.referenceId = userStore.subscriptions[0]!.referenceId;
    return true;
  }

  if (to.meta.requiresAdminOrOwner) {
    await ensureValidUser();
    return isUserAdminOrOwner(to.query.referenceId as string) || { path: '/account' };
  }

  if (to.meta.requiresOwner) {
    await ensureValidUser();
    return isUserAnOwner(to.query.referenceId as string) || { path: '/account' };
  }
});

router.afterEach(to => {
  if (to.meta?.title) {
    document.title = `${to.meta.title} | Speedify`;
  }
});
